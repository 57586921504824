export const state = () => ({
  ua: /iPhone|iPad|iPod|Android|Windows Phone/.test(typeof window !== 'undefined' ? window.navigator.userAgent : ''),
  mobile: false,
  countries: {list: []},
});

export const strict = false;

export const mutations = {
  setMobile(state, value) {
    state.mobile = value;
  },
};

export const getters = {
  isMobile(state) {
    return state.mobile;
  },
};

export const actions = {
};